import React from 'react';

export const ModalContext = React.createContext({
  activeColor: '',
  forcedColor: '',
  closeModal: () => {},
  onModalComplete: () => {},
  openModal: () => {},
  unlockAllSection: () => {},
});
export default ModalContext;

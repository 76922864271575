import React from 'react';
import PropTypes from 'prop-types';

import LangContext from '../../lib/lang/LangContext';

import './ResourceList.scss';

const ResourceList = ({ linkList, onLinkClick, title }) => {
  return (
    <LangContext.Consumer>
      {({ lang, prefix }) => (
        <>
          <div className="resources-wrapper">
            {linkList.map((item, index) => {
              let link = '';
              if (item.src.match(/^https?:\/\//)) {
                link = item.src;
              } else {
                link = `${prefix}${lang}/${item.src}`;
              }
              return (
                <a
                  className="resource-link"
                  key={index}
                  href={link}
                  rel="noopener noreferrer"
                  target="_blank"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                ></a>
              );
            })}
          </div>
        </>
      )}
    </LangContext.Consumer>
  );
};

ResourceList.propTypes = {
  linkList: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      src: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onLinkClick: PropTypes.func,
};

export default ResourceList;

import React from 'react';
import ReactModal from 'react-modal';

import ModalContext from '../Modal/ModalContext';
import ModalContent from '../ModalContent/ModalContent';
import './Modal.scss';

const Modal = ({ modals }) => {
  return (
    <ModalContext.Consumer>
      {({ activeModalID, closeModal }) => {
        if (activeModalID in modals) {
          return (
            <ReactModal
              appElement={document.body}
              className="modal"
              isOpen={true}
              overlayClassName="overlay"
              onRequestClose={closeModal}
              shouldCloseOnOverlayClick={true}
            >
              <ModalContent data={modals[activeModalID]} />
            </ReactModal>
          );
        }
        return '';
      }}
    </ModalContext.Consumer>
  );
};

export default Modal;

import 'core-js/es6/';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
import './style/index.scss';
import content from './content_FR.json';

document.addEventListener('DOMContentLoaded', function() {
  ReactDOM.render(<App content={content} />, document.getElementById('root'));
});

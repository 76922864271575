export const TRACKING_CONFIG = {
  autocomplete: {
    quiz: true,
    slider: true,
    playlist: true,
  },
  // because we might encapsulate all the code in the iframe
  // set it to window.parent if the module is called inside an iframe
  container: window,
  sendCompleteStatus: true,
  stepLock: false,
  globalLock: true,
};

export default {
  TRACKING_CONFIG,
};

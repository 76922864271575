import React, { PureComponent } from 'react';

import { withSnackbar } from 'notistack';
import VideoBg from 'reactjs-videobg';
import mp4 from '../../img/layout/background.mp4';
import poster from '../../img/layout/background.jpg';
import Home from '../Home/Home';
import Menu from '../Menu/Menu';
import MenuContext from '../Menu/MenuContext';
import Modal from '../Modal/Modal';
import ModalContext from '../Modal/ModalContext';
import TrackingContext from '../../lib/tracking/TrackingContext';

import './Container.scss';

class Container extends PureComponent {
  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
    this.state = {
      activeColor: '',
      activeMenuID: '',
      activeModalID: '',
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onModalComplete = this.onModalComplete.bind(this);
    this.displayMobile = this.displayMobile.bind(this);
  }

  closeMenu(id) {
    this.setState({
      activeMenuID: '',
      activeColor: '',
    });
  }

  openMenu(id, color) {
    const scrollY =
      this.contentRef.current.getBoundingClientRect().top + window.scrollY;
    window.scroll(0, scrollY);
    this.setState({
      activeMenuID: id,
      activeColor: color,
    });
  }
  displayMobile() {
    return document.documentElement.clientWidth <= 640;
  }

  closeModal(id) {
    this.setState({
      activeModalID: '',
    });
  }

  openModal(id) {
    this.setState({
      activeModalID: id,
    });
  }

  onModalComplete(id) {
    const { setVisited } = this.context;
    const { enqueueSnackbar } = this.props;
    const message = setVisited(id);
    if (message) {
      enqueueSnackbar(message, {
        variant: 'success',
      });
    }
  }

  render() {
    const { page, unlockAllSection } = this.props;
    const { activeColor, activeMenuID, activeModalID } = this.state;
    return (
      <ModalContext.Provider
        value={{
          activeColor,
          activeModalID,
          openModal: this.openModal,
          unlockAllSection: { unlockAllSection },
          onModalComplete: this.onModalComplete,
          closeModal: this.closeModal,
        }}
      >
        <MenuContext.Provider
          value={{
            activeColor,
            activeMenuID,
            contentModel: this.contentRef,
            openMenu: this.openMenu,
            closeMenu: this.closeMenu,
          }}
        >
          {!this.displayMobile() && (
            <VideoBg poster={poster} wrapperClass="video-background">
              <VideoBg.Source src={mp4} type="video/mp4" />
            </VideoBg>
          )}
          <div className="global" ref={this.globalRef}>
            <div className="page">
              <div className="container">
                <div className="content" ref={this.contentRef}>
                  <Home boxList={page.boxes} welcome={page.welcome} toaster={page.toaster}/>
                </div>
                <Menu menus={page.menus} />
              </div>
            </div>
          </div>
          <Modal modals={page.modals} />
        </MenuContext.Provider>
      </ModalContext.Provider>
    );
  }
}

Container.contextType = TrackingContext;

export default withSnackbar(Container);

import React from 'react';
import MenuContent from '../MenuContent/MenuContent';
import MenuContext from './MenuContext';

const Menu = ({ menus }) => {
  return (
    <MenuContext.Consumer>
      {({ activeMenuID }) => {
        const activeMenu = menus.filter(menu => menu.id === activeMenuID);
        if (activeMenu && activeMenu.length === 1) {
          return <MenuContent {...activeMenu[0]} />;
        }
        return '';
      }}
    </MenuContext.Consumer>
  );
};

export default Menu;

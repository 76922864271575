import React, { PureComponent } from 'react';

import cn from 'classnames';
import Box from '../Box/Box';
import BoxTitle from '../BoxTitle/BoxTitle';
import LangContext from '../../lib/lang/LangContext';
import MenuContext from '../Menu/MenuContext';
import ModalContext from '../Modal/ModalContext';

import './MenuContent.scss';
import TrackingContext from '../../lib/tracking/TrackingContext';

class MenuContent extends PureComponent {
  constructor(props) {
    super(props);
    this.closing = false;
    this.menuRef = React.createRef();
    this.closeMenu = this.closeMenu.bind(this);
    this.state = {
      animate: false,
    };
  }

  componentDidMount() {
    const { contentModel } = this.context;
    const model = contentModel.current;
    const menu = this.menuRef.current;
    // const padding = parseFloat(window.getComputedStyle(model).paddingTop);
    // menu.style.height = `${model.clientHeight - padding}px`;
    menu.style.width = `${model.clientWidth}px`;
    requestAnimationFrame(() => {
      this.setState({
        animate: true,
      });
    });
  }

  componentDidUpdate() {
    if (this.closing) {
      setTimeout(() => {
        const { closeMenu } = this.context;
        const { id } = this.props;
        this.isReady = false;
        closeMenu(id);
      }, 200);
    }
  }

  closeMenu() {
    this.closing = true;
    this.setState({
      animate: false,
    });
  }

  render() {
    const { boxes, subtitle, title } = this.props;
    const { animate } = this.state;
    return (
      <LangContext.Consumer>
        {({ lang }) => (
          <ModalContext.Consumer>
            {({ activeColor, openModal, forcedColor }) => (
              <div
                className={cn('menu', activeColor)}
                ref={this.menuRef}
                style={{ opacity: animate ? 1 : 0 }}
              >
                <span
                  title="Fermer"
                  className="close"
                  onClick={this.closeMenu}
                />
                <header>
                  <div className="head">
                    <h4 className="title">
                      <BoxTitle title={title} />
                    </h4>
                    <p className="subtitle">
                      <BoxTitle title={subtitle} />
                    </p>
                  </div>
                </header>
                {boxes && boxes.length > 0 && (
                  <ul className="menu_content">
                    {boxes.map((box, index) => {
                      if (box.target) {
                        return (
                          <TrackingContext.Consumer>
                            {({ isGlobalLocked }) => (
                              <Box
                                key={index}
                                onClick={() => {
                                  isGlobalLocked()
                                    ? openModal('modal0')
                                    : openModal(
                                        `${box.target.type}${box.target.id}`,
                                      );
                                }}
                                {...box}
                              />
                            )}
                          </TrackingContext.Consumer>
                        );
                      }
                      return <Box key={index} {...box} />;
                    })}
                  </ul>
                )}
              </div>
            )}
          </ModalContext.Consumer>
        )}
      </LangContext.Consumer>
    );
  }
}

MenuContent.contextType = MenuContext;

export default MenuContent;

import React from 'react';

import './SpecificList.scss';

const SpecificList = props => {
  return (
    <>
      <ul className="specific-list">
        {props.content.map((name, index) => {
          return <li key={index}>{name}</li>;
        })}
      </ul>
    </>
  );
};
export default SpecificList;

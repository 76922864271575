import { TRACKING_CONFIG } from '../../config';

class Section {
  constructor(obj) {
    this.blocking = obj.required && !obj.visited;
    this.id = obj.id;
    this.locked = obj.locked;
    this.pathInTree = this.initPath();
    this.required = obj.required;
    this.stepList = {};
    this.completeMessage = obj.completeMessage;
    this.visited = obj.visited;
  }

  static generatePath(id) {
    const genealogy = id.split('.');
    if (genealogy.length === 1) {
      return id;
    }
    return genealogy.join('.stepList.');
  }

  getDepthLevel() {
    return (this.id.match(/\./g) || []).length + 1;
  }

  getBlocking() {
    return this.blocking;
  }

  getId() {
    return this.id;
  }

  getIterableStepList() {
    if (Object.keys(this.stepList) === 0) {
      return false;
    }
    return Object.values(this.stepList);
  }

  getPath() {
    return this.pathInTree;
  }

  getRequired() {
    return this.required;
  }

  getStepList() {
    return this.stepList;
  }

  getCompleteMessage() {
    if (typeof this.completeMessage === 'function') {
      return this.completeMessage({
        id: this.id,
      });
    }
    return this.completeMessage;
  }

  harmonizeRequiredStatus() {
    const iterableStepList = this.getIterableStepList();
    if (!iterableStepList) {
      return false;
    }
    iterableStepList.forEach(step => {
      step.harmonizeRequiredStatus();
      if (step.getRequired()) {
        this.required = true;
        if (this.required && !this.visited && TRACKING_CONFIG.stepLock) {
          this.blocking = true;
        }
        return false;
      }
    });
  }

  initPath() {
    return Section.generatePath(this.id);
  }

  isBlocking() {
    return this.blocking;
  }

  isLocked() {
    return this.locked;
  }

  isRequired() {
    return this.required;
  }

  isVisited() {
    return this.visited;
  }

  setVisited() {
    this.visited = true;
    this.unblock();
  }

  unblock() {
    this.blocking = false;
  }

  unlock() {
    this.locked = false;
  }

  unlockOptionalSections() {
    const iterableStepList = this.getIterableStepList();
    if (!iterableStepList) {
      return false;
    }
    iterableStepList.forEach(step => {
      if (!step.isRequired()) {
        step.unlock();
      }
    });
  }
}

export default Section;

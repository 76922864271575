import React from 'react';
import './SpecificExample.scss';

const SpecificExample = props => {
  return (
    <div className="specific-example">
      <div dangerouslySetInnerHTML={{ __html: props.contentText }} />
      <img src={`${props.contentImgUrl}`} alt="alt" />
      <legend dangerouslySetInnerHTML={{ __html: props.contentImgLegend }} />
    </div>
  );
};

export default SpecificExample;

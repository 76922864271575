import React from 'react';

import './BoxTitle.scss';

const BoxTitle = ({ title }) => {
  if (title && Array.isArray(title)) {
    return (
      <React.Fragment>
        {title.map(item => (
          <React.Fragment key={item}>
            <span>{item}</span>
            <br />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }else{
    return(
      <React.Fragment>
      <span>{title}</span>
      <br />
    </React.Fragment>
    )

  }
  return null;
};

export default BoxTitle;

import React from 'react';
import PropTypes from 'prop-types';

import LangContext from '../../../../lib/lang/LangContext';

import './Static.scss';

const Static = ({
  images,
  isBackable,
  isNextable,
  hasExtra,
  extraContent,
  closeExtra,
  goToMainSlide,
  goToExtra,
  goToNext,
  goToPrevious,
  extraButtonText,
}) => {
  const responsive = document.documentElement.clientWidth <= 640;
  let content = '';
  if (images && images.length > 0) {
    content = (
      <LangContext.Consumer>
        {({ lang, prefix }) => (
          <div className="static">
            {images.map((image, index) => {
              if (image.href) {
                return (
                  <a
                    key={index}
                    href={`${prefix}${lang}/${image.href}`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <img
                      src={`${prefix}${lang}/${image.src}`}
                      alt={`${prefix}${lang}/${image.src}`}
                    />
                  </a>
                );
              } else if (responsive) {
                return (
                  <img
                    src={`${prefix}${lang}/${image.mobileSrc}`}
                    alt={`${prefix}${lang}/${image.mobileSrc}`}
                    key={index}
                  />
                );
              } else {
                return (
                  <img
                    src={`${prefix}${lang}/${image.src}`}
                    alt={`${prefix}${lang}/${image.src}`}
                    key={index}
                  />
                );
              }
            })}
          </div>
        )}
      </LangContext.Consumer>
    );
  }
  return (
    <div>
      {isBackable && !extraContent && (
        <button
          className="slider-button previous"
          onClick={goToPrevious}
        ></button>
      )}
      {isBackable && extraContent && (
        <button
          className="slider-button previous extra-previous"
          onClick={goToPrevious}
        ></button>
      )}

      {isNextable && !extraContent && (
        <button className="slider-button next" onClick={goToNext}></button>
      )}
      {isNextable && extraContent && (
        <button
          className="slider-button next extra-next"
          onClick={goToNext}
        ></button>
      )}
      {hasExtra && (
        <div className="extra-content">
          <button className="slider-button extra" onClick={goToExtra}>
            {extraButtonText}
          </button>
          <div className="extra-decoration" />
        </div>
      )}
      {closeExtra && (
        <button className="slider-button return" onClick={goToMainSlide}>
          FERMER
        </button>
      )}
      {content}
    </div>
  );
};

Static.propTypes = {
  images: PropTypes.array.isRequired,
  isBackable: PropTypes.bool.isRequired,
  isNextable: PropTypes.bool.isRequired,
  hasExtra: PropTypes.bool.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  closeExtra: PropTypes.bool.isRequired,
  goToMainSlide: PropTypes.func.isRequired,
};

Static.defaultProps = {
  hasExtra: false,
};

export default Static;

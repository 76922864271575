import React from 'react';
import cn from 'classnames';

import BoxTitle from '../BoxTitle/BoxTitle';
import LangContext from '../../lib/lang/LangContext';
import TrackingContext from '../../lib/tracking/TrackingContext';

import './Box.scss';

const Box = ({
  className,
  color,
  image,
  isFromHome,
  isRounded,
  onClick,
  order,
  title,
  titleMobile,
  trackingWatcher,
  type,
}) => {
  return (
    <LangContext.Consumer>
      {({ lang, prefix }) => {
        let content;
        let titleComponent = false;
        if (title && titleMobile) {
          titleComponent = (
            <>
              <div className="hidden-mobile">
                <BoxTitle title={title} />
              </div>
              <div className="hidden-desktop">
                <BoxTitle title={titleMobile} />
              </div>
            </>
          );
        }
        switch (type) {
          case 'empty':
            content = <img src={image} alt={image} />;
            break;
          case 'illustration':
            content = (
              <figure className="illustration">
                <img src={image} alt={image} />
                <figcaption>{titleComponent}</figcaption>
              </figure>
            );
            break;
          case 'normal':
          default:
            content = titleComponent;
            break;
        }
        return (
          <TrackingContext.Consumer>
            {({ isVisited, isLocked, isGlobalLocked }) => {
              function onBoxClick() {
                if (!onClick) {
                  return false;
                }
                if (isGlobalLocked()) {
                  return onClick();
                }
                if (!(trackingWatcher && trackingWatcher.id)) {
                  return onClick();
                }
                if (isLocked(trackingWatcher.id)) {
                  return false;
                }
                onClick();
              }
              return (
                <div
                  className={cn('box', className, color, {
                    rounded: isRounded,
                    empty: type === 'empty',
                    linked: isFromHome,
                  })}
                  onClick={onBoxClick}
                  order={order}
                >
                  {trackingWatcher && isVisited(trackingWatcher.id) && (
                    <div className="visited-filter"></div>
                  )}
                  {trackingWatcher && isLocked(trackingWatcher.id) && (
                    <div className="locked-filter"></div>
                  )}
                  {content && content}
                </div>
              );
            }}
          </TrackingContext.Consumer>
        );
      }}
    </LangContext.Consumer>
  );
};

export default Box;

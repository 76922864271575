import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import './VideoPlayer.scss';

const VIDEO_RATIO = 16 / 9;

const VideoPlayer = ({
  onStart,
  autoplay,
  url,
  width,
  height,
  poster,
  subtitles,
}) => {
  const sizeSettings = {};
  let subtitlesConfig;
  if (width === undefined) {
    width = document.documentElement.clientWidth;
  }
  if (height === undefined) {
    height = width / VIDEO_RATIO;
  }
  if (autoplay === undefined) {
    autoplay = true;
  }
  if (subtitles !== undefined) {
    subtitlesConfig = {
      kind: 'subtitles',
      src: subtitles,
      default: false,
      label: 'Français',
      srcLang: 'fr',
    };
  }
  if (height <= 360 || width <= 640) {
    if (height < 360) {
      width = height * VIDEO_RATIO;
    } else {
      height = width / VIDEO_RATIO;
    }
  }
  sizeSettings.height = height;
  sizeSettings.width = width;
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
            tracks: [subtitlesConfig],
          },
        }}
        controls
        onStart={onStart}
        playing={autoplay}
        url={url}
        fileConfig={{
          attributes: { poster: poster },
        }}
        {...sizeSettings}
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  onEnded: PropTypes.func,
  onStart: PropTypes.func,
  poster: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;

export const closeMenu = {
  en: 'Close menu',
  fr: 'Fermer ce menu',
};

export const closeModal = {
  en: 'Close modal',
  fr: 'Fermer ce modal',
};

export const questionYN = {
  en: 'True or False?',
  fr: 'Vrai ou Faux ?',
};

export const correct = {
  en: 'True',
  fr: 'Vrai',
};

export const incorrect = {
  en: 'False',
  fr: 'Faux',
};

export const validate = {
  en: 'Validate answer',
  fr: 'Valider la réponse',
};

export const completeMessage = {
  en: (args) => `You completed the section ${args.id}!`,
  fr: (args) => `Vous avez terminé la section ${args.id} !`,
}

export default {
  closeMenu,
  closeModal,
  questionYN,
  correct,
  incorrect,
  validate,
  completeMessage,
};

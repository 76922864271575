import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { questionYN, correct, incorrect } from '../../../../lib/lang/i18n';
import LangContext from '../../../../lib/lang/LangContext';

import './Quiz.scss';

class Quiz extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswer: false,
    };
    this.validateAnswer = this.validateAnswer.bind(this);
  }

  generateQuestionSlide(
    title,
    question,
    answerList,
    answers,
    image,
  ) {
    const { isBackable, goToPrevious } = this.props;
    const { lang, prefix } = this.context;
    return (
      <>
        <div>
          {isBackable && (
            <div
              className="slider-button previous"
              onClick={goToPrevious}
            ></div>
          )}
        </div>
        <div className="quiz-question">
          <div className="adornment">
            <span className="question-title">{title}</span>
          </div>
          <p dangerouslySetInnerHTML={{ __html: question }}/>
          <div className={cn('answer-group')}>{answers}</div>
          <div
            className={cn('quiz-footer', answerList.length > 2 ? 'large' : '')}
            style={{ backgroundImage: `url('${prefix}${lang}/${image}')` }}
          >
            <button className="validate" onClick={this.validateAnswer}>
              Valider
            </button>
          </div>
        </div>
      </>
    );
  }

  selectAnswer(answer) {
    this.setState({
      selectedAnswer: answer,
    });
  }

  validateAnswer() {
    const { goToNext } = this.props;
    const { selectedAnswer } = this.state;
    if (selectedAnswer !== false) {
      goToNext();
    }
  }

  render() {
    const { lang } = this.context;
    const { selectedAnswer } = this.state;
    const { answerList, image, question, title, type, modalTitle } = this.props;
    let quizContent = '';
    if (type === 'question-yn' && question) {
      const answerComponent = (
        <>
          <button
            className={cn('fixed-width', { selected: selectedAnswer === 0 })}
            onClick={() => this.selectAnswer(0)}
          >
            {correct[lang]}
          </button>
          <button
            className={cn('fixed-width', { selected: selectedAnswer === 1 })}
            onClick={() => this.selectAnswer(1)}
          >
            {incorrect[lang]}
          </button>
        </>
      );
      quizContent = this.generateQuestionSlide(
        questionYN[lang],
        modalTitle,
        question,
        answerList,
        answerComponent,
        image,
      );
    } else if (type === 'question' && answerList && answerList.length > 0) {
      const answerComponent = (
        <>
          {answerList.map((answer, index) => (
            <button
              className={cn('fixed-width', {
                selected: selectedAnswer === index,
              })}
              key={index}
              onClick={() => this.selectAnswer(index)}
            >
              {answer}
            </button>
          ))}
        </>
      );
      quizContent = this.generateQuestionSlide(
        title,
        question,
        answerList,
        answerComponent,
        image,
      );
    }
    return quizContent;
  }
}

Quiz.contextType = LangContext;

Quiz.propTypes = {
  answerList: PropTypes.array,
  goToNext: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string.isRequired,
};

export default Quiz;

import React from 'react';
import PropTypes from 'prop-types';

import LangContext from '../../../../lib/lang/LangContext';
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';
import './EmbeddedVideo.scss';

const EmbeddedVideo = ({
  isBackable,
  isNextable,
  goToNext,
  goToPrevious,
  video,
  videoPoster,
  subtitles,
  imageLayout,
  htmlText,
  title,
  debriefAnswer,
}) => {
  const clientWidth = document.documentElement.clientWidth;
  const content = (
    <LangContext.Consumer>
      {({ lang, prefix }) => (
        <>
          {imageLayout && (
            <div
              className="embedded-image"
              style={{
                backgroundImage: `url('${prefix}${lang}/${imageLayout}'
                  )`,
              }}
            />
          )}
          {!imageLayout && <div className="no-embedded-image" />}
          <div className="content embedded-content-video">
            {debriefAnswer && (
              <h3
                className="bool-title"
                dangerouslySetInnerHTML={{ __html: debriefAnswer }}
              />
            )}
            {!debriefAnswer && <div className="no-bool-title" />}
            <div className="rich-content">
              <div
                className="rich-text"
                dangerouslySetInnerHTML={{ __html: htmlText }}
              />
              <VideoPlayer
                url={`${prefix}${lang}/${video}`}
                width={clientWidth >= 640 ? 426 : undefined}
                poster={`${prefix}${lang}/${videoPoster}`}
                subtitles={`${prefix}${lang}/${subtitles}`}
              />
            </div>
          </div>
        </>
      )}
    </LangContext.Consumer>
  );
  return (
    <div>
      {isBackable && (
        <button
          className="slider-button previous"
          onClick={goToPrevious}
        ></button>
      )}
      {isNextable && (
        <button className="slider-button next" onClick={goToNext}></button>
      )}
      {content}
    </div>
  );
};

EmbeddedVideo.propTypes = {
  isBackable: PropTypes.bool.isRequired,
  isNextable: PropTypes.bool.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
};

EmbeddedVideo.defaultProps = {};

export default EmbeddedVideo;

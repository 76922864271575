import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { Button } from '@material-ui/core';
import './Drawer.scss';

const drawer = ({ toaster, onClose }) => {
  return (
    <Drawer open={true} anchor={toaster.side}>
      <div className="toaster">
        <p>{toaster.content}</p>
        <Button onClick={onClose}>{toaster.accept}</Button>
      </div>
    </Drawer>
  );
};

export default drawer;

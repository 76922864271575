import React, { PureComponent } from 'react';

import Alert from '../Alert/Alert';
import Box from '../Box/Box';
import Drawer from '../Drawer/Drawer';
import MenuContext from '../Menu/MenuContext';
import ModalContext from '../Modal/ModalContext';
import TrackingContext from '../../lib/tracking/TrackingContext';
import './Home.scss';

class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: true,
      toasterOpen: true,
    };
    this.closeAlert = this.closeAlert.bind(this);
    this.closeToaster = this.closeToaster.bind(this);
  }

  closeAlert() {
    this.setState({
      alertOpen: false,
    });
  }

  closeToaster() {
    this.setState({
      toasterOpen: false,
    });
  }

  render() {
    const { boxList, welcome, toaster } = this.props;
    const { alertOpen, toasterOpen } = this.state;
    return (
      <>
        {toasterOpen && (
          <TrackingContext.Consumer>
            {({ isGlobalLocked }) => {
              if (isGlobalLocked()) {
                return (
                  <Drawer
                    onClose={this.closeToaster}
                    toaster={toaster}
                  ></Drawer>
                );
              }
            }}
          </TrackingContext.Consumer>
        )}
        {alertOpen && welcome && (
          <TrackingContext.Consumer>
            {({ getVisitedList }) => {
              const visitedList = getVisitedList();
              if (!visitedList || visitedList.length === 0) {
                return (
                  <Alert
                    acceptMessage={welcome.accept}
                    onAccept={this.closeAlert}
                    onClose={this.closeAlert}
                    onReject={this.closeAlert}
                    title={welcome.title}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: welcome.content }}
                    />
                  </Alert>
                );
              } else {
                return '';
              }
            }}
          </TrackingContext.Consumer>
        )}
        <MenuContext.Consumer>
          {({ openMenu }) => (
            <ModalContext.Consumer>
              {({ openModal }) => (
                <TrackingContext.Consumer>
                  {({ getVisitedList, isGlobalLocked }) => (
                    <div className="home">
                      {boxList &&
                        boxList.length > 0 &&
                        boxList.map((box, index) => {
                          const onClick = () => {
                            if (
                              isGlobalLocked() === true &&
                              box.target.type !== 'menu'
                            ) {
                              openModal('modal0');
                            } else {
                              if (box.target.type === 'menu') {
                                openMenu(
                                  `${box.target.type}${box.target.id}`,
                                  box.color,
                                );
                              } else if (box.target.type === 'modal') {
                                openModal(`${box.target.type}${box.target.id}`);
                              }
                            }
                          };
                          if (box.target) {
                            return (
                              <Box
                                isFromHome
                                key={index}
                                onClick={onClick}
                                visitedList={getVisitedList()}
                                {...box}
                              />
                            );
                          }
                          return <Box isFromHome key={index} {...box} />;
                        })}
                    </div>
                  )}
                </TrackingContext.Consumer>
              )}
            </ModalContext.Consumer>
          )}
        </MenuContext.Consumer>
      </>
    );
  }
}

export default Home;

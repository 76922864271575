import { TRACKING_CONFIG } from '../../config';
import i18n from '../lang/i18n';
import Section from './Section';
import TrackingContext from './TrackingContext';

class Tracking {
  static DEFAULT_SECTION = {
    locked: TRACKING_CONFIG.stepLock,
    required: false,
    completeMessage: i18n.completeMessage.fr,
    visited: false,
    globalLocked: TRACKING_CONFIG.globalLock,
  };

  static instance;
  static trackedSectionList;
  static tree = {};
  static stepCount = 0;
  static visitedList = [];

  static buildTree(sectionList) {
    for (
      let depthLevel = 1;
      depthLevel <= Tracking.getTreeMaxDepth(sectionList);
      ++depthLevel
    ) {
      sectionList.forEach(section => {
        Tracking.integrateSectionFromDepthLevel(section, depthLevel);
      });
    }
  }

  static extractTrackedSectionList(section) {
    const out = [];
    for (let key in section) {
      const item = section[key];
      if (item.hasOwnProperty('tracking')) {
        out.push(
          new Section({
            ...Tracking.DEFAULT_SECTION,
            ...item.tracking,
          }),
        );
      }
      if (typeof item === 'object' || Array.isArray(item)) {
        out.push(...Tracking.extractTrackedSectionList(item));
      }
    }
    return out;
  }

  static forceConsistentRequiredTree() {
    for (let key in Tracking.tree) {
      const section = Tracking.tree[key];
      section.harmonizeRequiredStatus();
    }
  }

  static getNextSectionInTree(section) {
    const id = section.getId();
    let nextID = '';
    if (!id.includes('.')) {
      nextID = String(parseInt(id, 10) + 1);
    } else {
      const remain = id.substr(0, id.lastIndexOf('.'));
      const increment = id.substr(id.lastIndexOf('.') + 1);
      nextID = `${remain}.${parseInt(increment, 10) + 1}`;
    }
    return Tracking.getSectionInTree(nextID);
  }

  static getSectionInTree(id) {
    if (!id) {
      throw new Error(
        `You are supposed to give a tracking id to get a tree section, ${id} given.`,
      );
    }
    if (!id.includes('.')) {
      return Tracking.tree[id];
    }
    return Tracking.resolveSection(Section.generatePath(id));
  }

  static getTreeMaxDepth(sectionList) {
    let max = 0;
    sectionList.forEach(section => {
      if (section.getDepthLevel() > max) {
        max = section.getDepthLevel();
      }
    });
    return max;
  }
  static getVisited() {
    if (TRACKING_CONFIG.container.hasOwnProperty('getVisited')) {
      Tracking.visitedList = TRACKING_CONFIG.container.getVisited();
      Tracking.visitedList.forEach(item => {
        Tracking.setVisited(item);
      });
    }
  }
  static getGlobalLocked() {
    if (TRACKING_CONFIG.container.hasOwnProperty('getGlobalLocked')) {
      Tracking.globalLocked = TRACKING_CONFIG.container.getGlobalLocked();
      TRACKING_CONFIG.container.setGlobalLocked(Tracking.globalLocked);
    }
  }

  static getVisitedList() {
    return Tracking.visitedList;
  }

  static init(module) {
    const trackedSectionList = Tracking.extractTrackedSectionList(module);
    Tracking.buildTree(trackedSectionList);
    Tracking.forceConsistentRequiredTree();
    Tracking.stepListCount(Tracking.tree);
    Tracking.getVisited();
    Tracking.getGlobalLocked();
  }

  static integrateSectionFromDepthLevel(section, depthLevel) {
    if (section.getDepthLevel() !== depthLevel) {
      return false;
    }
    if (!Tracking.isSectionInTree(section)) {
      Tracking.integrateSectionIntoTree(section);
    }
  }

  static integrateSectionIntoTree(section) {
    const segments = section.getPath().split('.');
    segments.reduce((deep, seg, i) => {
      if (segments.length - 1 === i) {
        deep[seg] = section;
      }
      return deep[seg];
    }, Tracking.tree);
  }

  static isBlocking(sectionID) {
    const section = Tracking.getSectionInTree(sectionID);
    return section.isBlocking();
  }

  static isLocked(sectionID) {
    const section = Tracking.getSectionInTree(sectionID);
    return section.isLocked();
  }

  static isRequired(sectionID) {
    const section = Tracking.getSectionInTree(sectionID);
    return section.isRequired();
  }

  static isSectionInTree(section) {
    try {
      return !!Tracking.resolveSection(Tracking.tree, section.getPath());
    } catch (err) {
      return false;
    }
  }

  static isVisited(sectionID) {
    const section = Tracking.getSectionInTree(sectionID);
    return section.isVisited();
  }

  static resolveSection(path) {
    const segments = path.split('.');
    let element = Tracking.tree;
    segments.forEach(seg => {
      element = element[seg];
    });
    return element;
  }
  static isGlobalLocked() {
    if (TRACKING_CONFIG.container.hasOwnProperty('getGlobalLocked')) {
      return TRACKING_CONFIG.container.getGlobalLocked();
    }
  }

  static setCompleteStatus() {
    if (!TRACKING_CONFIG.sendCompleteStatus) {
      return false;
    }
    if (TRACKING_CONFIG.container.hasOwnProperty('setCompleteStatus')) {
      TRACKING_CONFIG.container.setCompleteStatus();
    }
  }

  static setScore(value) {
    if (TRACKING_CONFIG.container.hasOwnProperty('setScore')) {
      TRACKING_CONFIG.container.setScore(value);
    }
  }

  static setVisited(sectionID) {
    // call the setVisited function in SCORM, etc...
    if (TRACKING_CONFIG.container.hasOwnProperty('setVisited')) {
      const getVisitedResult = TRACKING_CONFIG.container.setVisited(sectionID);
      let score;
      score = Math.round((getVisitedResult.length / Tracking.stepCount) * 100);
      if (!isNaN(score)) {
        Tracking.setScore(score);
      }
      if (getVisitedResult.length === Tracking.stepCount) {
        Tracking.setCompleteStatus();
      }
    }
    // locally set section to visited
    const section = Tracking.getSectionInTree(sectionID);
    section.setVisited();
    // unlock next section
    Tracking.unlockNextSection(section);
    // eventually update all parent sections
    return Tracking.updateParentVisitedStatus(section);
  }

  static stepListCount(sectionHierarchy) {
    for (let key in sectionHierarchy) {
      const section = sectionHierarchy[key];
      if (Object.keys(section.getStepList()).length === 0) {
        ++Tracking.stepCount;
      } else {
        Tracking.stepListCount(section.stepList);
      }
    }
  }
  static unlockAllSection() {
    const sectionList = Tracking.tree;
    for (let key in sectionList) {
      if (sectionList[key] !== undefined) {
        Tracking.unlockNextSection(sectionList[key]);
        for (let index in sectionList[key].stepList) {
          if (sectionList[key].stepList[index] !== undefined) {
            Tracking.unlockNextSection(sectionList[key].stepList[index]);
          }
        }
      }
    }
    Tracking.globalLocked = false;
    if (TRACKING_CONFIG.container.hasOwnProperty('setGlobalLocked')) {
      TRACKING_CONFIG.container.setGlobalLocked(false);
    }
  }

  static unlockNextSection(section) {
    const next = Tracking.getNextSectionInTree(section);
    if (next) {
      next.unlock();
    }
  }

  static updateParentVisitedStatus(section) {
    const id = section.getId();
    if (!id.includes('.')) {
      return false;
    }
    const parentID = id.substr(0, id.lastIndexOf('.'));
    const parent = Tracking.getSectionInTree(parentID);
    const wasBlocked = parent.getBlocking();
    let hasUnblocked = false;
    if (!Object.values(parent.getStepList()).some(el => !el.isVisited())) {
      parent.setVisited();
      Tracking.updateParentVisitedStatus(parent);
      Tracking.unlockNextSection(parent);
      hasUnblocked = true;
    }
    if (!Object.values(parent.getStepList()).some(el => el.isBlocking())) {
      parent.unblock();
      parent.unlockOptionalSections();
      Tracking.unlockNextSection(parent);
      hasUnblocked = true;
    }
    if (wasBlocked && hasUnblocked) {
      return parent.getCompleteMessage();
    }
    return false;
  }
}

export default Tracking;

import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import SliderContext from '../Slider/SliderContext';
import LangContext from '../../../../lib/lang/LangContext';
import '../Quiz/Quiz.scss';
import './FinalQuiz.scss';

class FinalQuiz extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswer: false,
    };
    this.validateAnswer = this.validateAnswer.bind(this);
  }

  generateQuestionSlide(title, question, answerList, answers, image) {
    const { lang, prefix } = this.context;
    return (
      <SliderContext.Consumer>
        {({ saveUserSession }) => (
          <>
            <div className="quiz-question">
              <div className="adornment">
                <span className="question-title">{title}</span>
              </div>
              <p dangerouslySetInnerHTML={{ __html: question }}/>
              <div className={cn('answer-group')}>{answers}</div>
              <div
                className={cn(
                  'quiz-footer',
                  answerList.length > 2 ? 'large' : '',
                )}
                style={{ backgroundImage: `url('${prefix}${lang}/${image}')` }}
              >
                <button
                  className="validate"
                  onClick={() => this.validateAnswer(saveUserSession)}
                >
                  Valider
                </button>
              </div>
            </div>
          </>
        )}
      </SliderContext.Consumer>
    );
  }

  selectAnswer(answer) {
    this.setState({
      selectedAnswer: answer,
    });
  }

  validateAnswer(saveUserSession) {
    const { goToNext } = this.props;
    const { goodAnswer } = this.props;
    const { selectedAnswer } = this.state;
    const { questionId } = this.props;
    const { answerList } = this.props;
    if (selectedAnswer !== false) {
      let response = answerList[selectedAnswer] === goodAnswer;
      goToNext();
      saveUserSession(questionId, response);
    }
  }

  render() {
    const { selectedAnswer } = this.state;
    const { answerList, image, question, title } = this.props;
    let quizContent = '';
    const answerComponent = (
      <>
        {answerList.map((answer, index) => (
          <button
            className={cn('fixed-width', {
              selected: selectedAnswer === index,
            })}
            key={index}
            onClick={() => this.selectAnswer(index)}
          >
            {answer}
          </button>
        ))}
      </>
    );
    quizContent = this.generateQuestionSlide(
      title,
      question,
      answerList,
      answerComponent,
      image,
    );
    return quizContent;
  }
}

FinalQuiz.contextType = LangContext;

FinalQuiz.propTypes = {
  answerList: PropTypes.array,
  goToNext: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string.isRequired,
};

export default FinalQuiz;

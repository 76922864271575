import React from 'react';
import PropTypes from 'prop-types';

import LangContext from '../../../../lib/lang/LangContext';

import './EmbeddedContent.scss';

const EmbeddedContent = ({
  isBackable,
  isNextable,
  goToNext,
  goToPrevious,
  icon,
  image,
  htmlText,
  debriefAnswer,
  debriefTitle,
}) => {
  let content = (
    <LangContext.Consumer>
      {({ lang, prefix }) => (
        <>
          <div
            className="embedded-image"
            style={{
              backgroundImage: `url('${prefix}${lang}/${image}'
                  )`,
            }}
          >
            <div
              className="icon"
              style={{
                backgroundImage: `url('${prefix}${lang}/${icon}'
                  )`,
              }}
            />
          </div>
          <div className="content embedded-content">
            <h3
              className="bool-title"
              dangerouslySetInnerHTML={{ __html: debriefAnswer }}
            />
            <div className={'rich-text'}>
              {htmlText.map((content, index) => (
                <p
                  key={index}
                  className={'rich-text-content'}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </LangContext.Consumer>
  );
  return (
    <div>
      {isBackable && (
        <div className="slider-button previous" onClick={goToPrevious}></div>
      )}
      {isNextable && (
        <div className="slider-button next" onClick={goToNext}></div>
      )}
      {content}
    </div>
  );
};

EmbeddedContent.propTypes = {
  isBackable: PropTypes.bool.isRequired,
  isNextable: PropTypes.bool.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
};

EmbeddedContent.defaultProps = {};

export default EmbeddedContent;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import EmbeddedVideo from '../EmbeddedVideo/EmbeddedVideo';
import EmbeddedContent from '../EmbeddedContent/EmbeddedContent';
import Multi from '../Multi/Multi';
import Quiz from '../Quiz/Quiz';
import Static from '../Static/Static';
import FinalQuiz from '../FinalQuiz/FinalQuiz';
import Custom from '../../../Custom/Custom';

class Slide extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeExtra: this.initActiveExtra(props),
    };
    this.isBackable = this.isBackable.bind(this);
    this.goToNext = this.goToNext.bind(this);
    this.goToNextExtra = this.goToNextExtra.bind(this);
    this.goToPrevious = this.goToPrevious.bind(this);
    this.goToPreviousExtra = this.goToPreviousExtra.bind(this);
    this.goToMainSlide = this.goToMainSlide.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      activeExtra: this.initActiveExtra(nextProps),
    });
  }

  initActiveExtra(props) {
    let activeExtra = false;
    this.hasExtra = false;
    if (props.slide.extra) {
      activeExtra = -1;
      this.hasExtra = true;
    }
    return activeExtra;
  }
  goToFirst() {
    return this.props.goToFirst();
  }

  goToNext() {
    const { activeExtra } = this.state;
    if (this.hasExtra && activeExtra > -1) {
      this.goToNextExtra();
    } else {
      this.props.goToNext();
    }
  }
  goToPrevious() {
    const { activeExtra } = this.state;
    if (this.hasExtra && activeExtra > 0) {
      this.goToPreviousExtra();
    } else {
      this.props.goToPrevious();
    }
  }

  goToNextExtra() {
    this.setState(prevState => ({
      activeExtra: prevState.activeExtra + 1,
    }));
  }

  goToPreviousExtra() {
    this.setState(prevState => ({
      activeExtra: prevState.activeExtra - 1,
    }));
  }

  goToMainSlide() {
    this.setState({
      activeExtra: -1,
    });
  }

  isBackable() {
    const { isBackable } = this.props;
    const { activeExtra } = this.state;
    if (this.hasExtra && activeExtra > -1) {
      return this.isExtraBackable();
    } else {
      return isBackable;
    }
  }

  isNextable() {
    const { activeExtra } = this.state;
    const { isNextable } = this.props;
    if (this.hasExtra && activeExtra > -1) {
      return this.isExtraNextable();
    }
    return isNextable;
  }

  closeExtra() {
    const { activeExtra } = this.state;
    if (this.hasExtra && activeExtra > -1 && this.isNextable() === false) {
      return true;
    } else {
      return false;
    }
  }

  isExtraBackable() {
    const { activeExtra } = this.state;
    return activeExtra !== 0;
  }

  isExtraNextable() {
    const { activeExtra } = this.state;
    const { extraImageList } = this.props;
    return activeExtra !== extraImageList.length - 1;
  }

  generateContent(content) {
    const { activeExtra } = this.state;
    const { isLast, onComplete } = this.props;
    let sliderContent = '';
    switch (content.type) {
      case 'question-yn':
      case 'question':
        sliderContent = (
          <Quiz
            modalTitle={content.modalTitle}
            answerList={content.answers}
            goToNext={this.goToNext}
            question={content.question}
            image={content.image}
            title={content.title}
            type={content.type}
            isBackable={this.isBackable()}
            goToPrevious={this.goToPrevious}
          />
        );
        break;
      case 'final-question':
        sliderContent = (
          <FinalQuiz
            modalTitle={content.modalTitle}
            answerList={content.answers}
            goToNext={this.goToNext}
            question={content.question}
            image={content.image}
            title={content.title}
            type={content.type}
            goodAnswer={content.goodAnswer}
            questionId={content.questionId}
          />
        );
        break;
      case 'final-quiz-end':
      case 'final-quiz-start':
        sliderContent = (
          <Custom
            type={content.custom.type}
            customProps={content.custom.customProps}
            goToNext={this.goToNext}
          />
        );
        break;
      case 'multi':
        //console.log(content);
        sliderContent = (
          <Multi
            modalTitle={content.modalTitle}
            answerList={content.answers}
            goToNext={this.goToNext}
            question={content.question}
            image={content.image}
            title={content.title}
            type={content.type}
            isBackable={this.isBackable()}
            goToPrevious={this.goToPrevious}
          />
        );
        break;
      case 'embedded-video':
        sliderContent = (
          <EmbeddedVideo
            title={content.title}
            video={content.video.src}
            videoPoster={content.video.poster}
            subtitles={content.video.subtitles}
            htmlText={content.htmlText}
            imageLayout={content.imageLayout}
            debriefAnswer={content.debriefAnswer}
            isBackable={this.isBackable()}
            isNextable={this.isNextable()}
            isLast={isLast}
            goToNext={this.goToNext}
            goToPrevious={this.goToPrevious}
            backToMenu={onComplete}
          />
        );
        break;
      case 'embedded-content':
        sliderContent = (
          <EmbeddedContent
            debriefTitle={content.debriefTitle}
            image={content.image.src}
            icon={content.icon}
            htmlText={content.htmlText}
            debriefAnswer={content.debriefAnswer}
            isBackable={this.isBackable()}
            isNextable={this.isNextable()}
            isLast={isLast}
            goToNext={this.goToNext}
            goToPrevious={this.goToPrevious}
            backToMenu={onComplete}
          />
        );
        break;
      case 'static':
      default:
        sliderContent = (
          <Static
            isBackable={this.isBackable()}
            isNextable={this.isNextable()}
            isLast={isLast}
            hasExtra={this.hasExtra && activeExtra === -1}
            images={content.images}
            extraButtonText={
              this.hasExtra && content.extra !== undefined
                ? content.extra.buttonText
                : false
            }
            extraContent={activeExtra !== false && activeExtra > -1}
            closeExtra={this.closeExtra()}
            goToExtra={this.goToNextExtra}
            goToMainSlide={this.goToMainSlide}
            goToNext={this.goToNext}
            goToPrevious={this.goToPrevious}
            backToMenu={onComplete}
          />
        );
        break;
    }
    return sliderContent;
  }

  render() {
    const { slide } = this.props;
    const { activeExtra } = this.state;
    if (this.hasExtra && activeExtra !== -1) {
      const content = slide.extra.slides[activeExtra];
      return this.generateContent(content);
    }
    return this.generateContent(slide);
  }
}

Slide.propTypes = {
  hasExtra: PropTypes.bool.isRequired,
  goToNext: PropTypes.func.isRequired,
  goToPrevious: PropTypes.func.isRequired,
  isBackable: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  isNextable: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
};

Slide.defaultProps = {
  isBackable: false,
  isLast: false,
  isNextable: true,
};

export default Slide;

import React from 'react';
import {
  SpecificList,
  SpecificExample,
  Storyline,
  FinalQuizEnd,
  Passed,
  Fail,
  Certificate,
  SignupForm,
  FinalQuizStart,
} from './components';

const components = {
  SpecificList,
  SpecificExample,
  Storyline,
  FinalQuizEnd,
  Fail,
  Passed,
  Certificate,
  SignupForm,
  FinalQuizStart,
};

const Custom = ({ customProps, type, unlockAllSection, goToNext }) => {
  const CustomComponent = components[type];
  return (
    <CustomComponent
      {...customProps}
      unlockAllSection={unlockAllSection}
      goToNext={goToNext}
    />
  );
};

export default Custom;

import React, { PureComponent } from 'react';
import './SignupForm.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import QuickAccess from '../QuickAccess/QuickAccess';
import ModalContext from '../../../Modal/ModalContext';
const queryString = require('query-string');
const password = 'modération';

class SignupForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      passedLogin: false,
      formAccess: false,
    };
    this.setSignupSchema = this.setSignupSchema.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.setGlobalUnlock = this.setGlobalUnlock.bind(this);
  }
  sendRequest(values) {
    axios({
      method: 'post',
      //url: 'http://localhost/GITLAB/2019-09-10_VIN&STE/BACKEND/track.php',
      url: window.location.origin + '/signin/track.php',
      //url: 'http://production.upandgo.fr/2019-11-25_VIN&STE/tracking/track.php',
      //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: values,
    })
      .then(response => {
        //console.log(response);
        const params = queryString.parse(response.data);
        //const params = new URLSearchParams(response.data);
        //console.log(params);
        let pass = params.pass;
        //console.log(pass);
        if (pass === '1') {
          //console.log(pass)
          this.setState({
            formAccess: true,
          });
        }
        this.setGlobalUnlock();
        return response.data;
      })
      .catch(function(error) {
        //console.log(error)
      });
  }
  setGlobalUnlock() {
    const { unlockAllSection } = this.props;
    this.setState({
      passedLogin: true,
    });
    unlockAllSection();
    return null;
  }
  setSignupSchema() {
    return Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'Minimum 2 lettres')
        .max(40, 'Maximum 40 lettres')
        .required('Obligatoire'),
      lastName: Yup.string()
        .min(2, 'Minimum 2 lettres')
        .max(40, 'Maximum 40 lettres')
        .required('Obligatoire'),
      email: Yup.string()
        .email('email non valide')
        .required('Obligatoire'),
      company: Yup.string()
        .min(2, 'Minimum 2 lettres')
        .max(40, 'Maximum 40 lettres'),
      segment: Yup.mixed()
        .oneOf([
          'bieres',
          'cidres',
          'vins',
          'spiritueux',
          'aperitif',
          'agence',
          'etudiant',
          'avocat',
          'media',
          'autre',
        ])
        .required('Obligatoire'),
      condition: Yup.mixed().test({ test: condition => condition === true }),
    });
  }
  createSignupForm(schema) {
    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          condition: false,
        }}
        onSubmit={values => {
          this.sendRequest(values);
        }}
        validationSchema={schema}
      >
        {({ errors, touched }) => (
          <Form>
            <fieldset>
              <label>
                Nom<span className="required">*</span>
              </label>
              <Field
                name="lastName"
                placeholder="Votre nom"
                className={
                  errors.firstName && touched.firstName ? 'error' : null
                }
              />
            </fieldset>
            <fieldset>
              <label>
                Prénom<span className="required">*</span>
              </label>
              <Field
                name="firstName"
                placeholder="Votre prénom"
                className={
                  errors.firstName && touched.firstName ? 'error' : null
                }
              />
            </fieldset>
            <fieldset>
              <label>
                Email<span className="required">*</span>
              </label>
              <Field
                name="email"
                type="email"
                placeholder="Votre email"
                className={errors.mail && touched.mail ? 'error' : null}
              />
            </fieldset>
            <fieldset>
              <label className="large">Entreprise / Organisation</label>
              <Field
                name="company"
                className={errors.company && touched.company ? 'error' : null}
              />
            </fieldset>
            <fieldset>
              <label className="large">
                Secteur<span className="required">*</span>
              </label>
              <div className="select-container">
                <Field
                  component="select"
                  name="segment"
                  className={errors.segment && touched.segment ? 'error' : null}
                  defaultValue
                >
                  <option disabled value>
                    Choisir
                  </option>
                  <option value="bieres">bières</option>
                  <option value="cidres">cidres</option>
                  <option value="vins">vins</option>
                  <option value="aperitif">vins d'apéritif</option>
                  <option value="spiritueux">spiritueux</option>
                  <option value="agence">agence de communication</option>
                  <option value="etudiant">étudiant / chercheur</option>
                  <option value="avocat">avocat-conseil</option>
                  <option value="media">média-régie publicitaire</option>
                  <option value="autre">autre</option>
                </Field>
                <div className="select_arrow"></div>
              </div>
            </fieldset>
            <p className="form-required-text">
              * Les données obligatoires à la prise en compte sont signalées par
              un astérisque.
            </p>
            <fieldset>
              <Field
                name="condition"
                id="condition"
                value={true}
                type="checkbox"
                className={
                  errors.condition && touched.condition ? 'error' : null
                }
              />
              <label htmlFor="condition" className="disclamer">
                J'accepte que les données collectées fassent l'objet d'un
                traitement informatisé destiné à aux organisations éditrices du
                site. Conformément à la loi « Informatique et libertés » et au
                RGPD, vous disposez d'un droit d'accès, de rectification ou de
                radiation aux données personnelles vous concernant que vous
                pouvez exercer en écrivant à{' '}
                <b>
                  <a href="mailto:?to=ffs@spiritueux.fr">ffs@spiritueux.fr</a>
                </b>{' '}
              </label>
            </fieldset>
            <button type="submit">envoi du formulaire</button>
          </Form>
        )}
      </Formik>
    );
  }
  render() {
    const { passedLogin, formAccess } = this.state;
    if (passedLogin === false) {
      return (
        <div className="login-content">
          <p>
            L'accès aux contenus de formation est réservé aux utilisateurs
            enregistrés.
          </p>
          <div className="quick-access-container">
            <ModalContext.Consumer>
              {({ closeModal }) => (
                <QuickAccess
                  setGlobalUnlock={this.setGlobalUnlock}
                  closeModal={closeModal}
                />
              )}
            </ModalContext.Consumer>
          </div>
          <div className="signup-container">
            <p>
              Si vous ne disposez pas du mot de passe, veuillez renseigner le
              formulaire ci-dessous pour l'obtenir.
            </p>
            {this.createSignupForm(this.setSignupSchema())}
          </div>
        </div>
      );
    } else {
      if (formAccess === true) {
        return (
          <div className="access-container">
            <p>
              Votre demande a bien été prise en compte, voici le mot de passe
              vous permettant d'accéder au contenu
            </p>
            <ModalContext.Consumer>
              {({ closeModal }) => (
                <QuickAccess
                  password={password}
                  setGlobalUnlock={this.setGlobalUnlock}
                  closeModal={closeModal}
                />
              )}
            </ModalContext.Consumer>
          </div>
        );
      } else {
        return null;
      }
    }
  }
}

export default SignupForm;

import React from 'react';

import logo from '../../img/logo/Logo_LoiEvin.png';
import './Header.scss';

const Header = ({ info: { title, titleMobile } }) => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="content title hidden-mobile">
        {title &&
          React.Children.map(title, (item, index) => (
            <>
              <span key={index} dangerouslySetInnerHTML={{ __html: item }} />
              &nbsp;
            </>
          ))}
      </div>
      <div className="content title hidden-desktop">
        {title &&
          React.Children.map(titleMobile, (item, index) => (
            <>
              <span key={index} dangerouslySetInnerHTML={{ __html: item }} />
              <br />
            </>
          ))}
      </div>
    </header>
  );
};

export default Header;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './Slider.scss';
import Slide from '../Slide/Slide';
import SliderContext from './SliderContext';

class Slider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 0,
      session: {},
    };
    this.getNextSlide = this.getNextSlide.bind(this);
    this.getPreviousSlide = this.getPreviousSlide.bind(this);
    this.saveUserSession = this.saveUserSession.bind(this);
    this.getUserResult = this.getUserResult.bind(this);
    this.getQuestionsCount = this.getQuestionsCount.bind(this);
    this.getFirstSlide = this.getFirstSlide.bind(this);
  }
  saveUserSession(questionId, response) {
    const { session } = this.state;
    if (response !== undefined && questionId !== undefined) {
      session[questionId] = response;
    }
  }
  getUserResult() {
    const { session } = this.state;
    let nberOfCorrectResponse = 0;
    for (var index in session) {
      if (session[index]) {
        nberOfCorrectResponse++;
      }
    }
    return nberOfCorrectResponse;
  }
  getQuestionsCount() {
    const { session } = this.state;
    let nberOfQuestion = 0;
    for (var index in session) {
     //console.log(session)
      nberOfQuestion++;
    }
    return nberOfQuestion;
  }

  getNextSlide() {
    const { slideList, onComplete, onClose } = this.props;
    const { activeSlide } = this.state;
    if (slideList.length === activeSlide + 2) {
      onComplete();
    }
    if (slideList.length > activeSlide + 1) {
      this.setState(prevState => ({
        activeSlide: prevState.activeSlide + 1,
      }));
    } else {
      onClose();
    }
  }
  getFirstSlide() {
    this.setState({
      activeSlide: 0,
      session: {},
    });
  }

  getPreviousSlide() {
    const { activeSlide } = this.state;
    if (activeSlide > 0) {
      this.setState({
        activeSlide: activeSlide - 1,
      });
    }
  }

  render() {
    const { slideList, onComplete } = this.props;
    const { activeSlide } = this.state;
    const slide = slideList[activeSlide];
    const extraProps = {
      hasExtra:
        slideList[activeSlide].extra !== undefined &&
        slideList[activeSlide].extra.length !== 0,
    };
    if (extraProps.hasExtra) {
      extraProps.extraButtonText = slideList[activeSlide].extra.buttonText;
      extraProps.extraImageList = slideList[activeSlide].extra.slides;
    }
    return (
      <div className="modal-slider">
        <SliderContext.Provider
          value={{
            saveUserSession: this.saveUserSession,
            getUserResult: this.getUserResult,
            getQuestionsCount: this.getQuestionsCount,
            getFirstSlide: this.getFirstSlide,
          }}
        >
          <Slide
            goToNext={this.getNextSlide}
            goToFirst={this.getFirstSlide}
            goToPrevious={this.getPreviousSlide}
            activeSlide={activeSlide}
            slide={slide}
            onComplete={onComplete}
            isBackable={activeSlide !== 0}
            isNextable={activeSlide !== slideList.length - 1}
            isLast={activeSlide === slideList.length - 1}
            {...extraProps}
          />
        </SliderContext.Provider>
      </div>
    );
  }
}

Slider.propTypes = {
  onComplete: PropTypes.func,
};

export default Slider;

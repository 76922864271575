import React, { PureComponent } from 'react';
import cn from 'classnames';

import LangContext from '../../lib/lang/LangContext';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { TRACKING_CONFIG } from '../../config';
import TrackingContext from '../../lib/tracking/TrackingContext';
import { Scrollbars } from 'react-custom-scrollbars';

import './VideoPlaylist.scss';

class VideoPlaylist extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.updateVideoProperties(0),
      closed: false,
    };
    this.displayVideo = this.displayVideo.bind(this);
    this.closePlaylist = this.closePlaylist.bind(this);
  }

  componentWillMount() {
    // if 'autocomplete', all the videos are considered to be "visited"
    if (TRACKING_CONFIG.autocomplete.playlist) {
      const { onEnded, playlist } = this.props;
      playlist.forEach(item => {
        onEnded(item.tracking.id);
      });
    }
    this.displayVideo(0);
  }

  renderThumb() {
    const thumbStyle = {
      backgroundColor: 'white',
      borderRadius: '25px',
    };
    return <div style={thumbStyle} />;
  }

  updateVideoProperties(activeVideoIndex) {
    const { playlist } = this.props;
    return {
      activeVideoIndex,
      item: playlist[activeVideoIndex],
    };
  }

  displayVideo(activeVideoIndex) {
    this.setState(prevState => ({
      ...this.updateVideoProperties(activeVideoIndex),
    }));
  }

  closePlaylist() {
    this.setState(prevState => ({
      closed: !prevState.closed,
    }));
  }

  render() {
    const { onEnded, playlist } = this.props;
    const { activeVideoIndex, closed, item } = this.state;
    return (
      <LangContext.Consumer>
        {({ lang, prefix }) => (
          <>
            <div
              className={cn('video-playlist', {
                closed: closed,
              })}
            >
              <VideoPlayer
                url={`${prefix}${lang}/${item.video.src}`}
                onEnded={() => onEnded(item.tracking.id)}
                width={940}
                height={528}
                poster={item.video.poster}
              />
              <div className="video-playlist-container">
                <Scrollbars renderThumbVertical={this.renderThumb}>
                  <div className="video-playlist-content">
                    <div className="close-playlist">
                      <button onClick={this.closePlaylist}>
                        {closed ? '>' : '<'}
                      </button>
                    </div>
                    <div className="buttons">
                      <TrackingContext.Consumer>
                        {({ isVisited }) => {
                          return playlist.map((item, index) => {
                            const active = index === activeVideoIndex;
                            const visited = isVisited(item.tracking.id);
                            return (
                              <button
                                className={cn({ active, visited })}
                                onClick={() => this.displayVideo(index)}
                                key={index}
                              >
                                <span className="title">{item.title}</span>
                                <span className="description">
                                  {item.description}
                                </span>
                              </button>
                            );
                          });
                        }}
                      </TrackingContext.Consumer>
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </>
        )}
      </LangContext.Consumer>
    );
  }
}

export default VideoPlaylist;

import React from 'react';
import { SnackbarProvider } from 'notistack';
import Container from '../Container/Container';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import LangContext from '../../lib/lang/LangContext';
import TrackingContext from '../../lib/tracking/TrackingContext';

function App({ content: { lang, prefix, module } }) {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <TrackingContext.Consumer>
        {({ initTracking }) => {
          initTracking(module);
          return (
            <LangContext.Provider value={{ lang, prefix }}>
              <div className="body">
                <Header info={module.info} />
                <Container page={module.home} />
              </div>
              <Footer footerLinkList={module.footer.links} footerLogoList={module.footer.logos} />
            </LangContext.Provider>
          );
        }}
      </TrackingContext.Consumer>
    </SnackbarProvider>
  );
}

export default App;

import React, { PureComponent } from 'react';
import './Passed.scss';
import ModalContext from '../../../Modal/ModalContext';
import LangContext from '../../../../lib/lang/LangContext';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Custom from '../../Custom';

const certificate = function(props) {
  return <Custom type="Certificate" customProps={props} />;
};

class Passed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadLink: false,
      values: false,
    };
    this.createForm = this.createForm.bind(this);
    this.setCertificationSchema = this.setCertificationSchema.bind(this);
  }
  setCertificationSchema() {
    return Yup.object().shape({
      firstName: Yup.string()
        .min(2, 'Minimum 2 lettres')
        .max(20, 'Maximum 20 lettres')
        .required('Obligatoire'),
      lastName: Yup.string()
        .min(2, 'Minimum 2 lettres')
        .max(20, 'Maximum 20 lettres')
        .required('Obligatoire'),
    });
  }

  createForm(schema) {
    return (
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
        }}
        onSubmit={values => {
          this.setState({
            downloadLink: true,
            values: values,
          });
        }}
        validationSchema={schema}
      >
        {({ errors, touched }) => (
          <Form>
            <fieldset>
              <label>Nom :</label>
              <Field
                name="lastName"
                placeholder="Votre nom"
                className={
                  errors.firstName && touched.firstName ? 'error' : null
                }
              />
            </fieldset>
            <fieldset>
              <label>Prénom :</label>
              <Field
                name="firstName"
                placeholder="Votre prénom"
                className={
                  errors.firstName && touched.firstName ? 'error' : null
                }
              />
            </fieldset>
            <button type="submit">éditer</button>
          </Form>
        )}
      </Formik>
    );
  }
  render() {
    const { downloadLink, values } = this.state;
    if (downloadLink && values) {
      return certificate(values);
    } else {
      return (
        <LangContext.Consumer>
          {({ lang, prefix }) => (
            <ModalContext.Consumer>
              {({ closeModal }) => (
                <div className="passed-content">
                  <h1>BRAVO !</h1>
                  <div className="score">
                    <p>Vous avez obtenu la note de</p>
                    <p>
                      <span>{this.props.score}</span> /{' '}
                      <span>{this.props.count}</span>
                    </p>
                  </div>
                  <div className="passed-flex">
                    <div className="form">
                      <h2>certificat de formation : </h2>
                      {this.createForm(this.setCertificationSchema())}
                    </div>
                    <div className="resource">
                      <h2>télécharger les réponses :</h2>
                      <a
                        href={`${prefix}${lang}/${'6-quizFinal/resources/6-reponses_quiz.pdf'}`}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <img
                          src={`${prefix}${lang}/${'6-quizFinal/download_icon.png'}`}
                          alt={``}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </ModalContext.Consumer>
          )}
        </LangContext.Consumer>
      );
    }
  }
}
export default Passed;

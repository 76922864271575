import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = ({
  acceptMessage,
  children,
  onAccept,
  onClose,
  onReject,
  rejectMessage,
  title,
}) => {
  return (
    <>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {rejectMessage && onReject && (
            <Button onClick={onReject} color="primary">
              {rejectMessage}
            </Button>
          )}
          <Button onClick={onAccept} color="primary" autoFocus>
            {acceptMessage}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AlertDialog.propTypes = {
  acceptMessage: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onAccept: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onReject: PropTypes.func,
  rejectMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default AlertDialog;

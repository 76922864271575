import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import Close from '../../img/modal/close.png';
import footerLogo from '../../img/logo/logos_footer.jpg';
import { Scrollbars } from 'react-custom-scrollbars';

import './Footer.scss';

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeLinkName: false,
    };

    this.openLink = this.openLink.bind(this);
    this.closeLink = this.closeLink.bind(this);
  }
   renderThumb() {
    const thumbStyle = {
      backgroundColor: '#0b3c59',
      borderRadius: '25px',
    };
    return <div style={thumbStyle} />;
  }

  openLink(name) {
    this.setState({ activeLinkName: name });
  }

  closeLink() {
    this.setState({ activeLinkName: false });
  }

  render() {
    const { footerLinkList, footerLogoList } = this.props;
    const logoImages = footerLogoList.map(item =>
      require(`../../img/logo/${item.image}`),
    );
    const { activeLinkName } = this.state;

    return (
      <div className={'footer'}>
        {footerLinkList && footerLinkList.length > 0 && (
          <>
            <ul className="footer-menu">
              {footerLinkList.map((link, index) => {
                if (link.target.type === 'modal_link') {
                  return (
                    <li key={index} onClick={() => this.openLink(link.name)}>
                      {link.name}
                    </li>
                  );
                } else if (link.target.type === 'target_blank') {
                  return (
                    <li key={index}>
                      <a href={`${link.target.url}`} rel="noopener noreferrer">
                        {link.name}
                      </a>
                    </li>
                  );
                }
                return '';
              })}
            </ul>
            {footerLinkList.map((link, index) => (
              <ReactModal
                key={index}
                appElement={document.body}
                className="page_link"
                isOpen={activeLinkName === link.name}
                overlayClassName="overlay"
                onRequestClose={this.closeLink}
                shouldCloseOnOverlayClick={false}
              >
                <div className="modal-close" onClick={this.closeLink}>
                  <img src={Close} alt="close" title={''} />
                </div>
                 <Scrollbars renderThumbVertical={this.renderThumb}>
                <div className="footer-modal-content" dangerouslySetInnerHTML={{ __html: link.target.content }} />
                 </Scrollbars>
              </ReactModal>
            ))}
          </>
        )}
        {footerLogoList && footerLogoList.length > 0 && (
          <ul className="footer-logos">
            {footerLogoList.map((logo, index) => {
              return (
                <li key={index}>
                  <a target="_blank" href={`${logo.url}`} rel="noopener noreferrer">
                    <img
                      src={logoImages[index]}
                      alt={logo.alt}
                    ></img>
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
export default Footer;

import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import LangContext from '../../../../lib/lang/LangContext';

import '../Quiz/Quiz.scss';

class Multi extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswer: [],
    };
    this.validateAnswer = this.validateAnswer.bind(this);
  }

  generateQuestionSlide(title, question, answerList, answers, image) {
    const { isBackable, goToPrevious } = this.props;
    const { lang, prefix } = this.context;
    return (
      <>
        <div>
          {isBackable && (
            <div
              className="slider-button previous"
              onClick={goToPrevious}
            ></div>
          )}
        </div>
        <div className="quiz-question">
          <div className="adornment">
            <span className="question-title">{title}</span>
          </div>
          <p dangerouslySetInnerHTML={{ __html: question }}/>
          <div className={cn('multi answer-group')}>{answers}</div>
          <div
            className={cn('quiz-footer', answerList.length > 2 ? 'large' : '')}
            style={{ backgroundImage: `url('${prefix}${lang}/${image}')` }}
          >
            <button className="validate" onClick={this.validateAnswer}>
              Valider
            </button>
          </div>
        </div>
      </>
    );
  }

  selectAnswer(answer) {
    const { selectedAnswer } = this.state;
    if (!selectedAnswer.includes(answer)) {
      this.setState({
        selectedAnswer: Array.from(new Set([...selectedAnswer, answer])),
      });
    } else {
      for (var i = selectedAnswer.length - 1; i >= 0; i--) {
        if (selectedAnswer[i] === answer) {
          selectedAnswer.splice(i);
          this.setState({
            selectedAnswer: [...selectedAnswer],
          });
        }
      }
    }
  }

  isSelected(answerIndex) {
    const { selectedAnswer } = this.state;
    if (selectedAnswer.length > 0) {
      return selectedAnswer.includes(answerIndex);
    } else {
      return false;
    }
  }

  validateAnswer() {
    const { goToNext } = this.props;
    const { selectedAnswer } = this.state;
    if (selectedAnswer !== false) {
      goToNext();
    }
  }

  render() {
    const { answerList, image, question, title } = this.props;
    let quizContent = '';
    const answerComponent = (
      <>
        {answerList.map((answer, index) => (
          <button
            className={cn('fixed-width', {
              selected: this.isSelected(index),
            })}
            key={index}
            onClick={() => this.selectAnswer(index)}
          >
            {answer}
          </button>
        ))}
      </>
    );
    quizContent = this.generateQuestionSlide(
      title,
      question,
      answerList,
      answerComponent,
      image,
    );
    return quizContent;
  }
}

Multi.contextType = LangContext;

Multi.propTypes = {
  answerList: PropTypes.array,
  goToNext: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.string.isRequired,
};

export default Multi;

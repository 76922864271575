import React, { PureComponent } from 'react';
import './FinalQuizStart.scss';

class FinalQuizStart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { goToNext } = this.props;
    return (
      <>
        <div className="final-quiz-start">
          <p className="intro">
            10 Questions pour évaluer vos connaissances sur la loi Évin.{' '}
          </p>
          <p className="rules">
            Vous devez obtenir 80% de bonnes réponses pour valider votre
            parcours et obtenir votre certificat.
          </p>
          <button className="validate" onClick={goToNext}>
            Démarrer le quiz
          </button>
        </div>
      </>
    );
  }
}

export default FinalQuizStart;

import React from 'react';
import './Fail.scss';
import ModalContext from '../../../Modal/ModalContext';

const Fail = props => {
  return (
    <ModalContext.Consumer>
      {({ closeModal }) => (
        <div className="fail-content">
          <h1>Retentez votre chance</h1>
          <div className="score">
            <p>Vous n'avez obtenu que</p>
            <p>
              <span>{props.score}</span> / <span>{props.count}</span>
            </p>
            <p>à votre test</p>
          </div>
          <p className="info">
            Retournez sur la box pour approfondir le sujet ou bien recommencez
            le quiz.
          </p>
          <div className="button-group">
            <button onClick={props.getFirstSlide}>recommencer le quiz</button>
            <button onClick={closeModal}>revenir au menu</button>
          </div>
        </div>
      )}
    </ModalContext.Consumer>
  );
};

export default Fail;

import React, { PureComponent } from 'react';
import './FinalQuizEnd.scss';
import SliderContext from '../../../Slider/components/Slider/SliderContext';

import Custom from '../../Custom';

const UserScore = function(props) {
  if (props.score && props.score >= 8) {
    return <Custom type={'Passed'} customProps={props} />;
  } else {
    return <Custom type={'Fail'} customProps={props} />;
  }
};

class FinalQuizEnd extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <SliderContext.Consumer>
        {({ getUserResult, getQuestionsCount, getFirstSlide }) => (
          <UserScore
            score={getUserResult()}
            count={getQuestionsCount()}
            getFirstSlide={getFirstSlide}
          ></UserScore>
        )}
      </SliderContext.Consumer>
    );
  }
}

export default FinalQuizEnd;

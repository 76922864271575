import React from 'react';
import './Storyline.scss';
import LangContext from '../../../../lib/lang/LangContext';

const Storyline = props => {
  const width = document.documentElement.clientWidth;
  const source = width >= 750 ? props.src : props.mobile;

  return (
    <LangContext.Consumer>
      {({ lang, prefix }) => (
        <iframe
          title="digibox-flipbook"
          src={`${prefix}${lang}/${source}`}
          allowFullScreen={true}
        />
      )}
    </LangContext.Consumer>
  );
};

export default Storyline;

import React from 'react';
import Tracking from './Tracking';

export const TrackingContext = React.createContext({
  getVisitedList: Tracking.getVisitedList,
  initTracking: Tracking.init,
  isBlocking: Tracking.isBlocking,
  isLocked: Tracking.isLocked,
  isRequired: Tracking.isRequired,
  isVisited: Tracking.isVisited,
  setVisited: Tracking.setVisited,
  isGlobalLocked: Tracking.isGlobalLocked,
  unlockAllSection: Tracking.unlockAllSection,
});

export default TrackingContext;

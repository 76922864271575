import React, { PureComponent } from 'react';
import './QuickAccess.scss';
import { Formik, Form, Field } from 'formik';
const quickAccessPassword = ['modération', 'moderation'];

class QuickAccess extends PureComponent {
  constructor(props) {
    super(props);
    this.unlockContent = this.unlockContent.bind(this);
  }

  unlockContent(value) {
    const { setGlobalUnlock, closeModal } = this.props;
    if (value.password && quickAccessPassword.indexOf(value.password.toLowerCase()) !== -1){
      setGlobalUnlock();
      closeModal();
    } else {
      return false;
    }
  }
  render() {
    const { password } = this.props;
    return (
      <Formik
        initialValues={{
          password: password ? password : '',
        }}
        onSubmit={values => {
          this.unlockContent(values);
        }}
      >
        <Form>
            <div>
              {' '}
              <Field placeholder="mot de passe" name="password" />
            </div>
            <div>
              <button type="submit">accès box</button>
            </div>
        </Form>
      </Formik>
    );
  }
}
export default QuickAccess;

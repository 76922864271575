import React, { PureComponent } from 'react';
import date from 'date-and-time';
import './Certificate.scss';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  PDFDownloadLink,
  StyleSheet,
} from '@react-pdf/renderer';
class Certificate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.createStyle = this.createStyle.bind(this);
    this.generateCertificate = this.generateCertificate.bind(this);
  }
  // Create styles
  createStyle() {
    return StyleSheet.create({
      page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
        position: 'relative',
      },
      section: {
        textAlign: 'center',
        position: 'absolute',
        top: '43%',
        left: '0',
        right: '0',
        fontSize: '1.5cm',
        color: '#E6A30D',
      },

      image: {
        width: '100%',
        position: 'absolute',
      },
      bottom: {
        position: 'absolute',
        bottom: '10%',
        left: '5%',
        color: '#004157',
        fontSize: '1cm',
      },
    });
  }
  generateDownloadLink() {}
  generateCertificate(user) {
    // Font.register({
    //   family: 'Montserrat',
    //   src: 'https://fonts.googleapis.com/css?family=Montserrat&display=swap',
    // });
    const styles = this.createStyle();
    const { firstName, lastName } = user;
    const currentDate = date.format(new Date(), 'DD/MM/YYYY');
    return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <Image
            style={styles.image}
            src={'content/FR/6-quizFinal/certificate/layout-certificate.jpg'}
          />
          <View style={styles.section}>
            <Text>
              {firstName.toUpperCase()} {lastName.toUpperCase()}
            </Text>
          </View>
          <View style={styles.bottom}>
            <Text>{currentDate}</Text>
          </View>
        </Page>
      </Document>
    );
  }
  render() {
    const user = this.props;
    return (
      <div className="download-link">
        <PDFDownloadLink
          document={this.generateCertificate(user)}
          fileName="certificat.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading
              ? 'Votre certificat est en cours de création'
              : 'Cliquez ici pour télécharger votre certificat'
          }
        </PDFDownloadLink>
      </div>
    );
  }
}
export default Certificate;

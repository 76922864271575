import React from 'react';
import cn from 'classnames';

import BoxTitle from '../BoxTitle/BoxTitle';
import HeaderBackground from '../../img/interactive_map/header.jpg';
import LangContext from '../../lib/lang/LangContext';
import ModalContext from '../Modal/ModalContext';
import ResourceList from '../ResourceList/ResourceList';
import Slider from '../Slider';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import VideoPlaylist from '../VideoPlaylist/VideoPlaylist';
import Custom from '../Custom/Custom';

import './ModalContent.scss';
import TrackingContext from '../../lib/tracking/TrackingContext';

const ModalContent = ({
  data: {
    id,
    forcedColor,
    slides,
    title,
    titleMobile,
    video,
    playlist,
    tracking,
    resources,
    storyline,
    custom,
  },
}) => {
  let titleComponent = false;
  if (title && titleMobile) {
    titleComponent = (
      <>
        <div className="hidden-mobile">
          <BoxTitle title={title} />
        </div>
        <div className="hidden-desktop">
          <BoxTitle title={titleMobile} />
        </div>
      </>
    );
  }
  let width = 940;
  if (document.documentElement.clientWidth < 700) {
    width = document.documentElement.clientWidth;
  }

  let headerStyle = {};
  if (slides) {
    headerStyle = {
      backgroundImage: `url('${HeaderBackground}')`,
    };
  }
  return (
    <LangContext.Consumer>
      {({ lang, prefix }) => (
        <ModalContext.Consumer>
          {({ activeColor, closeModal, onModalComplete }) => (
            <div
              className={cn(
                'modal-container',
                activeColor,
                forcedColor,
                video ? 'stand-alone-video' : '',
                storyline ? 'storyline' : '',
              )}
            >
              <span title="Fermer" className="close" onClick={closeModal} />
              {!video && (
                <header
                  className={cn('modal-title', {
                    'video-header': video || playlist || resources,
                  })}
                >
                  <h5>{titleComponent}</h5>
                </header>
              )}
              {video && (
                <VideoPlayer
                  url={`${prefix}${lang}/${video.src}`}
                  onStart={() => onModalComplete(tracking.id)}
                  poster={video.poster}
                  subtitles={`${prefix}${lang}/${video.subtitles}`}
                  width={width}
                />
              )}
              {slides && slides.length > 0 && (
                <Slider
                  slideList={slides}
                  onComplete={() => {
                    onModalComplete(tracking.id);
                  }}
                  onClose={() => closeModal(id)}
                />
              )}
              {playlist && playlist.length > 0 && (
                <VideoPlaylist playlist={playlist} onEnded={onModalComplete} />
              )}
              {resources && resources.length > 0 && (
                <ResourceList
                  title={title}
                  linkList={resources}
                  onLinkClick={onModalComplete}
                />
              )}
              {custom && custom.type.length > 0 && (
                <TrackingContext.Consumer>
                  {({ unlockAllSection }) => (
                    <>
                      <Custom
                        type={custom.type}
                        customProps={custom.customProps}
                        unlockAllSection={unlockAllSection}
                      />
                    </>
                  )}
                </TrackingContext.Consumer>
              )}
            </div>
          )}
        </ModalContext.Consumer>
      )}
    </LangContext.Consumer>
  );
};

export default ModalContent;
